import { useMedia } from 'react-use';

const useBreakpoints = () => {
  const sm = useMedia('(min-width: 640px)');
  const md = useMedia('(min-width: 768px)');
  const lg = useMedia('(min-width: 1024px)');
  const xl = useMedia('(min-width: 1280px)');
  return {
    sm,
    md,
    lg,
    xl,
  };
};

export default useBreakpoints;
