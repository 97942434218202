export const navs = [
  { href: '/', icon: 'icon-home', name: '首页' },
  { href: '/search', icon: 'icon-find', name: '找朋友' },
  { href: '/moments', icon: 'icon-discover', name: '交友圈' },
  { href: '/chats', icon: 'icon-msg', name: '消息' },
  { href: '/pricing/vip', icon: 'icon-msg', name: 'VIP' },
  { href: '/settings', icon: 'icon-me', name: '个人设置' },
];

export function match(pathname, href) {
  if (href === '/') {
    return pathname === href;
  }
  return pathname.includes(href);
}
