import _ from 'lodash';

import Avatar from '~/components/Avatar';
import Card from '~/components/index/Card';

const FeaturedUserCard = ({ users = [], title = '最新加入', limit = 8 }) => {
  return (
    <Card title={title}>
      <div className="py-3 px-5 grid grid-cols-4 gap-2 md:py-6 md:px-8 lg:grid-cols-8">
        {_.shuffle(users)
          .slice(0, limit)
          .map(({ id, sex, update_time }) => (
            <Avatar key={id} size="w-16 h-16 md:w-20 md:h-20" uid={id} sex={sex} update_time={update_time} />
          ))}
      </div>
    </Card>
  );
};

export default FeaturedUserCard;
