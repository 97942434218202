import useSWR from 'swr';
import axios, { request as getRequest } from '~/services/axios';

export default function useRequest(request, { isServer, initialData, ...config } = {}) {
  if (isServer) {
    return getRequest({ method: 'get', url: request });
  }
  const { data, error, ...rest } = useSWR(
    request && JSON.stringify(request),
    () => axios(request || {}).then((response) => response.data),
    {
      revalidateOnFocus: false,
      errorRetryCount: 0,
      ...config,
      initialData,
    }
  );
  return {
    data,
    res: data,
    isLoading: !error && !data,
    error,
    ...rest,
  };
}
