import _ from 'lodash';
import { useLocalStorage } from 'react-use';
import { Skeleton } from 'antd';
import IconFont from '~/components/IconFont';
import { getImageAddress } from '~/utils/commons';

import Card from '~/components/index/Card';
import { Photo } from '~/services/agent';
import Gallery from '~/components/Gallery';

const ImageCard = () => {
  const [num, setNum] = useLocalStorage('photo-num', 0);
  const { res, isLoading } = Photo.getRandomPhotos(num);

  return (
    <Card
      title="新上传"
      rightLink={
        <a
          className="flex items-center text-greyish-brown"
          onClick={() => {
            setNum((num + 1) % 60);
          }}>
          <span className="block tracking-wider md:text-base">换一批</span>
          <IconFont type="icon-shuffle" className="block md:text-xl md:ml-1" />
        </a>
      }>
      <div className="p-4">
        {isLoading ? (
          <Skeleton paragraph={{ rows: 4 }} />
        ) : (
          <Gallery
            images={_.map(
              res?.data?.slice(0, 3),
              ({ pic_url, user_id, user_info: { nickname, sex, update_time } }) => ({
                src: getImageAddress(pic_url),
                uid: user_id,
                nickname,
                sex,
                update_time,
              })
            )}
            col={3}
            colClasses="w-1/3 h-32 ml-2 md:h-56"
            imageClasses="w-full h-full"
          />
        )}
      </div>
    </Card>
  );
};

export default ImageCard;
