import axios from 'axios';
import { message } from 'antd';

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
});

instance.interceptors.request.use(
  (config) => {
    config.url = encodeURI(config.url);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    message.error(error?.response?.data?.msg ?? error.message);
    throw error;
  }
);

export async function request(config) {
  return await instance(config)
    .then((data) => {
      return {
        res: data?.data,
      };
    })
    .catch((err) => {
      return {
        error: err,
        res: {
          errno: -1,
        },
      };
    });
}

export default instance;
