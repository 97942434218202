const Card = ({ title, rightLink, children }) => (
  <section className="bg-white-100 rounded-3xl shadow-card-200 divide-y divide-white-300">
    <div className="px-5 pt-4 pb-3 flex justify-between items-center md:px-6">
      <div className="text-lg tracking-widest font-semibold md:text-xl">
        <span className="text-primary-200">#</span>
        <span className="ml-2">{title}</span>
      </div>
      {rightLink}
    </div>
    {children}
  </section>
);

export default Card;
