import _ from 'lodash';
import Cookies from 'js-cookie';
import { User } from '~/services/agent';

import { cookieKeys } from '~/utils/commons';

export function useAuth() {
  const uid = Cookies.get(cookieKeys.BBS_UID_KEY);
  if (uid) {
    const noneLoginPages = _.find(['/banner'], (prefix) => window.location.pathname.startsWith(prefix));
    if (!noneLoginPages) {
      const { res, ...rest } = User.getMyProfile();
      if (rest?.error) {
        Cookies.remove(cookieKeys.BBS_UID_KEY, { path: '/', domain: '.1point3acres.com' });
        // window.location.href = bbsEndpoint.LOGOUT(window.location.href);
      } else {
        return {
          user: res?.user,
          isBan: res?.user?.status === 2,
          isLogin: !!res?.user,
          isRenzheng: res?.user?.renzheng || res?.credits > 200,
          isVip: res?.user?.vip_id > 0,
          isReviewing: res?.user?.status === 4, // 强制认证
          isActive: res?.user?.status !== 3,
          hasProfile: res?.user?.year !== 0,
          ...rest,
        };
      }
    }
  }

  return {
    user: {},
    isLogin: false,
    isBan: false,
    isRenzheng: false,
    isLoading: false,
    isReviewing: false,
    isActive: false,
    isVip: false,
    hasProfile: false,
  };
}
