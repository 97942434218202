import { useRequest, useInfiniteRequest } from '~/hooks';
import { request } from '~/services/axios';

const Shuoshuo = {
  getTrending: (type) => useRequest(`/friends/shuoshuos/trending/${type}`),
  getTopicList: (type, options) => useRequest(`/friends/shuoshuos/trending/${type}`, { ...options }),
  getShuoshuos: ({ ps = 20, pg = 1, uid, ...options }) =>
    useRequest(`/friends/shuoshuos?ps=${ps}${uid ? `&uid=${uid}` : ''}&pg=${pg}`, { ...options }),
};

const Photo = {
  getRandomPhotos: (num) => useRequest(`/friends/photos?id=${num}`),
  getUserPhotos: (uid) => useRequest(`/friends/users/${uid}/photos`),
  uploadUserPhotos: (data) =>
    request({
      method: 'post',
      url: '/friends/user/photos',
      headers: { 'content-type': 'multipart/form-data' },
      data,
    }),
  deleteUserPhoto: (photoId) =>
    request({
      method: 'delete',
      url: `/friends/user/photos/${photoId}`,
    }),
};

const UserList = {
  getUserList: ({ type = 'new', ps = 20, options = {} }) =>
    useInfiniteRequest(`/friends/users?type=${type}&ps=${ps}`, (data) => data.users, { ...options }),
  getRelationUsers: ({ ps = 20, type_id = 1, direction = 'to' }) =>
    useInfiniteRequest(`/friends/relations?ps=${ps}&type_id=${type_id}&direction=${direction}`, (data) => data.users),
  getTrendingUsers: ({ ps = 20, phb_type, order }) =>
    useInfiniteRequest(
      phb_type && order && `/friends/ranks?ps=${ps}&date_type=${phb_type}&type_name=${order}`,
      (data) => data.users
    ),
  getSearchUsers: (filters) =>
    useInfiniteRequest(`/friends/users-search?ps=20&filters=${JSON.stringify(filters)}`, (data) => data.users),
  getSimilarUsers: (uid) => useRequest(`/friends/similar-users?uid=${uid}`),
};

const User = {
  getMyProfile: () =>
    useRequest(`/friends/user`, {
      dedupingInterval: 1000 * 10,
    }),
  getUserProfile: (uid, options) => useRequest(uid ? `/friends/users/${uid}` : null, { ...options }),
  updateUserProfile: (data) =>
    request({
      method: 'put',
      url: `/friends/user`,
      data,
    }),
  updateUserSetting: (data) =>
    request({
      method: 'put',
      url: `/friends/user/settings`,
      data,
    }),
  updateUserAvatar: (data) =>
    request({
      method: 'put',
      url: '/friends/user/avatar',
      headers: { 'content-type': 'multipart/form-data' },
      data,
    }),
  report: (data) =>
    request({
      method: 'post',
      url: 'friends/report',
      data,
    }),
  checkin: () => request({ method: 'post', url: 'friends/credit-actions/daily-checkin' }),
  addToBlacklist: (data) =>
    request({
      method: 'post',
      url: 'friends/blacklists',
      data,
    }),
};

const Chats = {
  getChatList: (ps = 20, pg = 1) => useRequest(`/friends/pm-lists?pg=${pg}&ps=${ps}`),
  getNotificationList: (showAll) => useRequest(`/friends/user/notifications?show_all=${showAll ? 1 : 0}`),
  readNotification: (id = 0) =>
    request({
      method: 'put',
      url: `/friends/user/notifications/${id}`,
    }),
  getChatMessage: (uid, pg = 1, ps = 20) => useRequest(uid && `/friends/pm-members/${uid}/messages?pg=${pg}&ps=${ps}`),
  sendChatMessage: (uid, content) =>
    request({
      method: 'post',
      url: `/friends/pm-members/${uid}/messages`,
      data: {
        content,
      },
    }),
  deleteUser: (listId) =>
    request({
      method: 'delete',
      url: `/friends/pm-lists/${listId}`,
    }),
};

const Relation = {
  sendCrush: (uid) =>
    request({
      method: 'post',
      url: `/friends/relations/${uid}/crush`,
    }),
  getContactInfo: (uid) =>
    request({
      method: 'post',
      url: `/friends/relations/${uid}/check`,
    }),
  sendGift: (uid, giftId, quantity) =>
    request({
      method: 'post',
      url: `/friends/relations/${uid}/gift`,
      data: {
        gift_id: giftId,
        quantity,
      },
    }),
};

const Gift = {
  getGiftList: () => useRequest(`/friends/gifts`),
};

const Pricing = {
  getPackages: (category) =>
    request({
      method: 'get',
      baseURL: 'https://pay.1point3acres.com',
      url: `/api/packages?category=${category}`,
    }),
  getCreditLogs: () => useRequest(`/friends/user/credit-logs`),
  getCreditAction: () => useRequest(`/friends/credit-actions`),
};

export { Shuoshuo, Photo, UserList, User, Chats, Relation, Gift, Pricing };
