import { useSWRInfinite } from 'swr';
import axios, { request as getRequest } from '~/services/axios';

export default function useInfiniteRequest(request, format = (v) => v, { initialData, isServer, ...config } = {}) {
  if (isServer) {
    return getRequest({ method: 'get', url: request });
  }
  const { data, error, size, ...rest } = useSWRInfinite(
    (index) => request && `${request}&pg=${index + 1}`,
    (url) => axios(url).then((response) => format(response.data)),
    {
      revalidateOnFocus: false,
      errorRetryCount: 0,
      ...config,
      initialData,
    }
  );

  const isLoading = (!error && !data) || (size > 0 && data && typeof data[size - 1] === 'undefined');

  return {
    data: [].concat(...(data || [])),
    isLoading,
    hasMore: !isLoading && (data && data[size - 1] != null ? data[size - 1]?.length === 20 : true),
    error,
    size,
    ...rest,
  };
}
