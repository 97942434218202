import _ from 'lodash';

export const SEOMap = {
  '/': {
    title: '婚恋交友',
    description: '海外华人素质最高的婚恋交友网站。单身约会，相亲交友，寻找结婚对象，发现有趣灵魂最佳网站。',
  },
  '/moments': {
    title: '交友圈',
    description: '记录生活点滴，分享生活方式，并基于兴趣形成互动，找到志趣相投的人。',
  },
  '/search': {
    title: '找朋友',
    description: '根据性别，职业，身高，体重，居住地，家乡等各种条件筛选相亲交友对象',
  },
  '/trending': {
    title: '排行榜',
    description: '一亩三分地相亲交友网站暗恋最多，收到礼物最多，查看最多的用户排行榜',
  },
  '/users/special': {
    title: '最Special用户',
    description: '一亩三分地相亲交友网站最speicail的用户',
  },
  '/users/new': {
    title: '最新加入用户',
    description: '一亩三分地相亲交友网站最新加入的用户',
  },
  '/users/hot': {
    title: '最受欢迎用户',
    description: '一亩三分地相亲交友网站最热门的用户',
  },
  '/settings': {
    title: '设置',
    loginRequired: true,
  },
  '/settings/profile': {
    title: '资料修改',
    loginRequired: true,
  },
  '/settings/album': {
    title: '我的相册',
    loginRequired: true,
  },
  '/settings/wallet': {
    title: '我的钱包',
    loginRequired: true,
  },
  '/settings/notification': {
    title: '消息通知',
    loginRequired: true,
  },
  '/pricing/vip': {
    title: '购买VIP',
  },
  '/pricing/credits': {
    title: '购买红钻',
  },
  '/profile': {
    title: '个人页面',
  },
  '/chats': {
    title: '消息',
    loginRequired: true,
  },
  '/chats/system': {
    title: '系统消息',
    loginRequired: true,
  },
  '/relation/gift': {
    title: '关系-礼物',
    loginRequired: true,
  },
  '/relation/check': {
    title: '关系-查看',
    loginRequired: true,
  },
  '/relation/anlian': {
    title: '关系-暗恋',
    loginRequired: true,
  },
};

export const loginRequiredPages = _.transform(
  SEOMap,
  (result, value, key) => {
    if (value?.loginRequired) {
      result.push(key);
    }
  },
  []
);
