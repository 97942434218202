import React from 'react';
import Layout from '~/components/Layout';
import { IconCard, SpecialIconCard } from '~/components/IconCard';
import ImageCard from '~/components/index/ImageCard';
import TopicCard from '~/components/index/TopicCard';
import FeaturedUserCard from '~/components/index/FeaturedUserCard';
import { Shuoshuo, UserList } from '~/services/agent';

function Home({ users, newTopics }) {
  return (
    <Layout title="交友" hasTabBar>
      <div className="px-5 md:px-16">
        <section className="mt-8 px-5 flex justify-between items-center md:px-24 md:mt-10">
          <IconCard name="同城" icon="icon-location" href="/search" />
          <IconCard name="最热" icon="icon-ranking" href="/users/hot" />
          <IconCard name="最新" icon="icon-newuser" href="/users/new" />
          <SpecialIconCard name="Special" icon="icon-starts" href="/users/special" />
        </section>
        <section className="mt-6 md:mt-12">
          <ImageCard />
        </section>
        <section className="mt-6 md:mt-10">
          <FeaturedUserCard users={users} />
        </section>
        <section className="mt-6  md:space-x-6 md:mt-10">
          <TopicCard name="最新话题" type="new" topics={newTopics} />
        </section>
      </div>
    </Layout>
  );
}

export async function getStaticProps() {
  const { res: newUserRes } = await UserList.getUserList({ type: 'new', ps: 10, options: { isServer: true } });
  const { res: newTopicsRes } = await Shuoshuo.getTopicList('new', { isServer: true });
  return {
    props: {
      users: newUserRes?.users,
      newTopics: newTopicsRes?.data,
    },
    revalidate: 1800, // In seconds
  };
}

export default Home;
