import moment from 'moment';

const Time = ({ timestamp, fromNow, format = 'YYYY-MM-DD', ...rest }) => {
  const date = moment.unix(timestamp);
  const formatted = date.format(format);

  return (
    <>
      <time title={formatted} {...rest}>
        {fromNow ? date.fromNow() : formatted}
      </time>
    </>
  );
};

export default Time;
