import React from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { SEOMap } from '~/utils/seo_map';

const GeneralSEO = () => {
  const router = useRouter();
  const info = SEOMap[router.asPath?.split('?')[0]];
  if (!info) {
    return null;
  }
  return (
    <NextSeo
      title={`${info.title} | 一亩三分地`}
      description={info.description}
      additionalMetaTags={[
        {
          property: 'keywords',
          content: '美国,加拿大,海外,相亲,交友,寻找有趣的灵魂,一亩三分地',
        },
      ]}
      openGraph={{
        url: `https://match.1point3acres.com${router.asPath}`,
        title: info.title,
        description: info.description,
        site_name: `${info.title}`,
      }}
    />
  );
};

export default GeneralSEO;
