import Link from 'next/link';
import IconFont from '~/components/IconFont';

const IconCard = ({ name, icon, href }) => (
  <Link href={href}>
    <div className="text-center cursor-pointer">
      <div className="w-12 h-12 bg-gradient-to-br from-primary-200 to-primary-600 rounded-full shadow-icon md:w-20 md:h-20">
        <IconFont
          type={icon}
          className={`block pt-1 text-3xl text-white-100 md:text-5xl md:pt-2 ${icon === 'icon-newuser' ? 'pl-1' : ''}`}
        />
      </div>
      <div className="mt-3 text-xs  font-semibold text-primary-200 md:text-lg">{name}</div>
    </div>
  </Link>
);

const SpecialIconCard = ({ name, icon, href }) => (
  <Link href={href}>
    <div className="text-center cursor-pointer">
      <div className="w-12 h-12 bg-gradient-to-br  rounded-full shadow-icon-100 md:w-20 md:h-20">
        <IconFont type={icon} className="block pt-1 text-3xl text-primary-200 md:text-5xl md:pt-2" />
      </div>
      <div className="mt-3 text-xs font-semibold text-primary-200 md:text-lg">{name}</div>
    </div>
  </Link>
);

export { IconCard, SpecialIconCard };
