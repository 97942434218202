import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Badge } from 'antd';

import IconFont from '~/components/IconFont';
import { navs, match } from '~/utils/nav';
import { useAuth } from '~/hooks/withAuth';

const BottomTabNavigator = () => {
  const router = useRouter();
  const { user } = useAuth();
  const [standAlone, setStandalone] = React.useState(false);

  React.useEffect(() => {
    setStandalone(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  return (
    <footer
      className={`w-screen h-16 bg-white-100 px-8 ${
        standAlone ? 'pb-4' : ''
      } fixed bottom-0 rounded-t-2xl flex justify-between items-center shadow-card-100 md:hidden`}>
      {navs
        .filter(({ href }) => href !== '/pricing/vip')
        .map(({ href, icon }) => (
          <Link href={href} key={href}>
            <div className="px-5 py-4">
              <Badge size="small" offset={[5, 0]} count={href === '/chats' ? user?.new_message_count?.total : null}>
                <IconFont
                  type={icon}
                  className={`text-2xl ${match(router.pathname, href) ? 'text-primary-200' : 'text-black-300'} `}
                />
              </Badge>
            </div>
          </Link>
        ))}
    </footer>
  );
};

export default BottomTabNavigator;
