import Link from 'next/link';
import Avatar from '~/components/Avatar';
import Time from '~/components/Time';
import Card from '~/components/index/Card';

const RightButton = () => (
  <Link href="/moments">
    <a className="block text-greyish-brown tracking-wider md:text-base">查看全部&gt;</a>
  </Link>
);
const TopicCard = ({ name, topics }) => {
  return (
    <Card title={name} rightLink={<RightButton />}>
      <div className="divide-y divide-white-300">
        {topics?.map(({ id, content, user_id, ss_time, user_info }) => (
          <Link key={id} href={`/moments/${id}`}>
            <div className="pt-3 pb-6 pr-5 pl-3 flex cursor-pointer md:pl-6 md:pt-4 md:pb-8 md:pr-6">
              <Avatar uid={user_id} sex={user_info.sex} update_time={user_info.update_time} />
              <div className="ml-4 flex-1">
                <div className="flex justify-between items-center">
                  <strong className="font-medium text-base md:text-lg">{user_info.nickname}</strong>
                  <Time timestamp={ss_time} fromNow className="text-xs tracking-wider text-brown-grey md:text-base" />
                </div>
                <div className="line-clamp mt-1 text-sm text-brown-grey md:text-base">{content}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </Card>
  );
};

export default TopicCard;
