import React from 'react';
import { CaretUpOutlined } from '@ant-design/icons';
import { useWindowScroll } from 'react-use';

const BackTop = () => {
  const [visible, setVisible] = React.useState();
  const { y } = useWindowScroll();

  function handleClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  React.useEffect(() => {
    setVisible(Boolean(y) && y > 1500);
  }, [y]);

  return (
    <>
      {visible && (
        <button
          className="hidden md:flex fixed select-none z-1 flex-col items-center justify-center bg-to-b rounded-full text-white-100 shadow-button outline-none"
          onClick={handleClick}>
          <CaretUpOutlined className="text-white-100 text-2xl" />
          <span className="text-lg -mt-2">TOP</span>
        </button>
      )}
      <style jsx>{`
        button {
          width: 87px;
          height: 87px;
          transform: translateX(50%);
          top: 70%;
          z-index: 999;
        }
        @media (min-width: 768px) {
          button {
            right: calc((100vw - 48rem) / 2);
          }
        }
        @media (min-width: 1024px) {
          button {
            right: calc((100vw - 56rem) / 2);
          }
        }
        @media (min-width: 1280px) {
          button {
            right: calc((100vw - 64rem) / 2);
          }
        }
      `}</style>
    </>
  );
};

export default BackTop;
