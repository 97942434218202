import React from 'react';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { useRouter } from 'next/router';
import compareVersions from 'compare-versions';

import { cookieKeys } from '~/utils/commons';

export default function useAppInfo() {
  const [paymentAvailable, setPaymentAvailable] = React.useState(false);
  const [inApp, setInApp] = React.useState(false);
  const router = useRouter();
  React.useEffect(() => {
    const { query } = queryString.parseUrl(window.location.search);
    const appVersion = query?.app_version || Cookies.get(cookieKeys.APP_VERSION) || window?.appInfo?.majorVersion;
    setPaymentAvailable(!compareVersions.validate(appVersion) || compareVersions.compare(appVersion, '1.16.0', '<'));
    setInApp(Boolean(window?.appInfo));
    if (!_.isEmpty(query?.app_version)) {
      Cookies.set(cookieKeys.APP_VERSION, query.app_version);
      setTimeout(() => {
        const path = window.location.href.replace(window.location.search, '');
        router.replace(path, path, { shallow: true });
      }, 500);
    }
  }, []);
  return {
    paymentAvailable,
    inApp,
  };
}
