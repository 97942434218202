import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Badge } from 'antd';

import { navs, match } from '~/utils/nav';
import { useAuth } from '~/hooks/withAuth';
import { useAppInfo } from '~/hooks';

const NavBarItem = ({ name, href, active }) => (
  <Link href={href}>
    <span
      className={`text-xl text-center font-medium  uppercase cursor-pointer ${
        active ? 'text-primary-200' : 'text-greyish-brown'
      }`}>
      {name}
    </span>
  </Link>
);
const Navbar = () => {
  const router = useRouter();
  const { user } = useAuth();
  const { paymentAvailable } = useAppInfo();

  const filteredNavs = paymentAvailable ? navs : navs.filter(({ href }) => href !== '/pricing/vip');

  return (
    <div className="w-full h-24 bg-white-100 rounded-b-lg shadow-lg bg-dark-mode-hightlighted">
      <div className="max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-5xl mx-auto flex justify-between items-center px-16 h-full">
        {filteredNavs.slice(0, 3).map(({ href, name }) => (
          <NavBarItem key={name} name={name} href={href} active={match(router.pathname, href)} />
        ))}
        <div className="flex items-center">
          <Link href="/">
            <img src="/images/logo.svg" className="w-20 h-20 cursor-pointer" />
          </Link>
          <div>
            <div className="text-primary-200 text-sm text-center">一亩三分地</div>
            <div className="bg-to-r rounded-xl text-white-100 px-5">婚恋交友</div>
          </div>
        </div>
        {filteredNavs.slice(3).map(({ href, name }) => (
          <Badge key={name} count={href === '/chats' ? user?.new_message_count?.total : null}>
            <NavBarItem key={name} name={name} href={href} active={match(router.pathname, href)} />
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
