import Link from 'next/link';
import { getAvatarAddress } from '~/utils/commons';

const Avatar = ({ uid, size = 'w-16 h-16', noProfileLink, openInNewTab = false, sex = 0, update_time = 0 }) => {
  let borderColor = '#c9c9c9';
  if (sex === 1) {
    borderColor = 'linear-gradient(to bottom, #b7dcff, #729dff)';
  } else if (sex === 2) {
    borderColor = 'linear-gradient(to bottom, #ffb7b7, #ff8c7f)';
  }
  const avatarImage = (
    <div className={`${size} flex-shrink-0`}>
      <img
        className={`rounded-full object-cover border-opacity-50 border-solid border-2 border-transparent overflow-hidden ${
          noProfileLink ? '' : 'cursor-pointer'
        } ${size} transform transition hover:-rotate-6`}
        src={getAvatarAddress(uid, update_time)}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = '/images/noavatar_middle.gif';
        }}
      />
      <style jsx>{`
        div {
          position: relative;
          img {
            position: absolute;
            top: 0px;
            left: 0px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 100%;
            background: ${borderColor};
          }
        }
      `}</style>
    </div>
  );

  if (noProfileLink) {
    return avatarImage;
  }

  if (openInNewTab) {
    return (
      <a href={`/profile/${uid}`} target="_blank">
        {avatarImage}
      </a>
    );
  }

  return <Link href={`/profile/${uid}`}>{avatarImage}</Link>;
};

export default Avatar;
