import React from 'react';
import { Button } from 'antd';
import { useRouter } from 'next/router';

import Navbar from './Navbar';
import BottomTabNavigator from '~/components/BottomTabNavigator';
import GeneralSEO from '~/components/GeneralSEO';
import BackTop from '~/components/BackTop';
import IconFont from '~/components/IconFont';
import { useAppInfo } from '~/hooks';
import useBreakpoints from '~/hooks/useBreakpoints';

const Layout = ({ title, children, hasTabBar = false, pb = 'pb-20', background = 'md:bg-grey-200' }) => {
  const router = useRouter();
  const { inApp } = useAppInfo();
  const { md } = useBreakpoints();

  const handleClickBack = () => {
    router.back();
  };

  React.useEffect(() => {
    if (inApp && md && window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ webviewActionHeaderBackgroundColor: 'rgba(0,0,0,0.5)' }));
    }
  }, [inApp, md]);

  return (
    <>
      <GeneralSEO />
      <div>
        <div className="hidden md:block rectangle absolute left-0 top-0" />
        <div className="hidden md:block">
          <Navbar />
        </div>
        <div
          className={`context max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-5xl min-h-screen md:min-h-screen60 mx-auto ${background} md:mt-16 md:rounded-3xl shadow-lg`}>
          <header className="fixed z-20 flex md:hidden w-full h-12 px-3 justify-between items-center bg-gradient-to-r from-primary-200 to-primary-600">
            {router?.pathname !== '/' ? (
              <Button
                type="text"
                icon={<IconFont type="icon-left" className="text-2xl" />}
                className="text-white-100 mb-3"
                onClick={handleClickBack}
              />
            ) : (
              <span className="w-8" />
            )}
            <span className="text-white-100 text-base">{title}</span>
            <span className="w-6" />
          </header>
          <main className={`${pb} md:relative pt-12 md:pt-0`}>
            <BackTop />
            {children}
          </main>
        </div>
      </div>
      {hasTabBar && <BottomTabNavigator />}
      <style jsx>{`
        .rectangle {
          width: 100%;
          height: 524px;
          background: linear-gradient(121deg, #ff4c3a 14%, #e43d58 82%);
          clip-path: polygon(0% 0%, 0% 90%, 50% 100%, 100% 90%, 100% 0%);
          z-index: -1;
        }
        .context::before {
          content: '';
          clear: both;
          display: table;
        }
      `}</style>
    </>
  );
};

export default Layout;
